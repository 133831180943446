import * as Yup from 'yup';
import { FC, useState } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const initialValues = {
  email: process.env.REACT_APP_ENV === 'development' ? 'student@la.az' : '',
  password: process.env.REACT_APP_ENV === 'development' ? '123456' : '',
  terms: true,
  submit: null,
};

const LoginJWT: FC = () => {
  const [errMsg, setErrMsg] = useState(null);
  const { login } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((s) => !s);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('yup.email.correctly'))
      .max(255)
      .required(t('yup.email.required')),
    password: Yup.string().max(255).required(t('yup.password.required')),
  });

  const onSubmit = async (
    values,
    { setStatus, setSubmitting },
  ): Promise<void> => {
    try {
      await login(values.email, values.password);

      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      setErrMsg(err.message);

      setTimeout(() => {
        setErrMsg(null);
      }, 4000);

      if (isMountedRef.current) {
        setStatus({ success: false });
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              margin="normal"
              autoFocus
              helperText={touched.email && errors.email}
              label={t('inputs.labels.email')}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              margin="normal"
              helperText={touched.password && errors.password}
              label={t('inputs.labels.password')}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errMsg && (
              <Box>
                <Typography color="error">{errMsg}</Typography>
              </Box>
            )}
            <Box
              alignItems="center"
              display={{ xs: 'block', md: 'flex' }}
              justifyContent="flex-end"
              mt={1}
            >
              <Link component={RouterLink} to="/recover-password">
                <b>{t('login.lostPassword')}</b>
              </Link>
            </Box>

            {Boolean(touched.terms && errors.terms) && (
              <FormHelperText error>{errors.terms}</FormHelperText>
            )}

            <Button
              sx={{
                mt: 3,
              }}
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              {t('login.signIn')}
            </Button>
            <Box
              alignItems="center"
              display={{ xs: 'block', md: 'flex' }}
              justifyContent="center"
              gap={1}
              mt={1}
            >
              <Typography>{`${t('login.noAccount')} `}</Typography>
              <Link component={RouterLink} to="/register">
                <b>{t('login.signUp')}</b>
              </Link>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default LoginJWT;
