const enJSON = {
  lessons: {
    title: 'lessons',
  },
  myResults: {
    title: 'my results',
  },
  myFriends: {
    title: 'my friends',
  },
  popover: {
    title: 'profile',
    signOut: 'sign out',
    lessonMode: 'lesson mode',
  },
  pages: {
    home: 'home',
  },
  heading: {
    general: 'general',
    management: 'management',
  },
  profile: {
    title: 'profile',
    changeCover: 'change cover',
    tabs: {
      myAccount: 'my account',
      aboutMe: 'about me',
    },
  },
  inputs: {
    labels: {
      changePassword: 'change password',
      changeEmail: 'change email',
      currentPassword: 'current password',
      password: 'password',
      passwordConfirm: 'password confirm',
      email: 'email',
      name: 'name',
      surname: 'surname',
      gender: 'gender',
      fullName: 'full name',
      numberOne: 'number one',
      numberTwo: 'number two',
      university: 'university',
      interests: 'interests',
      dateOfBirth: 'date of birth',
      fatherName: 'father name',
      number: 'number',
      biography: 'biography',
      price: 'money',
      permissionName: 'permission name',
      description: 'description',
      code: 'code',
      tag: 'tag name',
      category: 'category',
      amount: 'amount',
      type: 'type',
      beforeEventDays: 'before event days',
      action: 'action name',
      task: 'task name',
      tourName: 'tour name',
      aboutTour: 'about tour',
      additionalNotes: 'additional notes',
      country: 'country',
      days: 'days',
      daysToDeadline: 'days to deadline',
      difficulty: 'çətinlik',
      equipmentName: 'equipment name',
      brand: 'brand',
      model: 'model',
      size: 'size',
      color: 'color',
      quantity: 'quantity',
      discount: 'discount',
      purchasePrice: 'purchase price',
      sellingPrice: 'selling price',
      rentPrice: 'rent price',
      inventoryName: 'inventory name',
      rentalCode: 'rental code',
      totalCost: 'total cost',
      startDate: 'start date',
      endDate: 'end date',
      finishDate: 'bitirmə tarixi',
      customer: 'customer',
      referal: 'referal',
      exacutedBy: 'which fulfills',
      user: 'user',
      certificateCode: 'certificate code',
      createdBy: 'created',
      allUsers: 'all users',
    },
    placeholders: {},
  },
  buttons: {
    confirm: 'confirm',
    importExcelFile: 'import excel file',
    add: 'add',
    addEvent: 'add event',
    addUser: 'add user',
    addHomework: 'add homework',
    delete: 'delete',
    cancel: 'cancel',
    archive: 'archive',
    addPermission: 'add permission',
    edit: 'edit permission',
    addTag: 'add tag',
    closeWindow: 'close the window',
    addExpense: 'add expense',
    addTransaction: 'add transaction',
    addAction: 'add action',
    addNewAction: 'add new action',
    addTask: 'add task',
    addNewTask: 'add new task',
    addNewTaskToParticipants: 'add new task to participants',
    addTour: 'add new tour',
    addEquipment: 'add new equipment',
    addNewEventTask: 'add a task to the event',
    addInventory: 'add inventory',
    addRental: 'add rental',
    addCertificate: 'add certificate',
    continue: 'continue',
  },
  yup: {
    currentPassword: 'current password is required',
    password: {
      required: 'password is required',
      min: 'password is required',
    },
    passwordConfirm: {
      required: 'confirm password is required.',
      correctly: 'does not match the repeat password',
    },
    email: {
      required: 'email is required',
      correctly: 'email not entered correctly',
    },
    inputs: {
      name: 'name is required',
      surname: 'surname is required',
      gender: 'gender is required',
      university: 'university is required',
      subject: 'subject is required',
      room: 'room is required',
      lessonStarting: 'lesson starting is required',
      lessonEnding: 'lesson ending is required',
      duration: 'duration is required',
      title: 'title is required',
      percentage: 'percentage is required',
      link: 'link is required',
      biography: 'biography is required',
      fatherName: 'father name is required',
      dateOfBirth: 'date of birth is required',
      price: 'Price is required',
      participant: 'participant is required',
      permissionName: 'permission name is required',
      description: 'permission description is required',
      permissionCode: 'permission code is required',
      amount: 'amount is required',
      task: 'task name is required',
      tag: 'tag is required',
      action: 'action name is required',
      tourName: 'tour name is required',
      brand: 'brand is required',
      quantity: 'quantity is required',
      discount: 'discount is required',
      purchasePrice: 'purchasePrice is required',
      sellingPrice: 'sellingPrice is required',
      rentPrice: 'rentPrice is required',
      customer: 'customer is required',
      totalCost: 'total cost is required',
      totalCostMin: 'the amount must be a minimum of 1',
      startDate: 'start date is required',
      endDate: 'end date is required',
      exacutedBy: 'which fulfills is required',
    },
  },
  login: {
    signIn: 'sign in',
    info: 'fill in the fields below to sign into your account.',
    lostPassword: 'lost password ?',
    signUp: 'sign up here',
  },
  recoverPassword: {
    title: 'recover password',
    info: 'enter the email used for registration to reset your password',
    sendPassword: 'send me a new password',
    signInAgain: 'want to try to sign in again?',
    clickHere: 'click here',
  },
  languageSwitcher: {
    title: 'language',
    tooltip: 'switch language',
  },
  modal: {
    delete: 'are you sure you want to delete?',
    archive: 'are you sure you want to archive?',
    amountAfterDiscount: 'amount after discount',
    addAmount: 'add amount',
    makePayment: 'make payment',
    allTasksOfParticipant: 'all tasks of participant',
    addNewAction: 'add new action',
    addNewTask: 'add new task',
    addNewParticipantTask: 'add a task for participants',
    addNewEventTask: 'add a task to the event',
    details: 'details',
    addPhoto: 'add photo',
    addInfoToTask: 'add information to the task',
  },
  table: {
    name: 'name',
    category: 'category',
    type: 'type',
    amount: 'amount',
    description: 'description',
    fullName: 'full name',
    actions: 'actions',
    code: 'code',
    tasks: 'tasks',
    beforeEventDays: 'before event days',
    brand: 'brand',
    model: 'model',
    color: 'color',
    size: 'size',
    quantity: 'quantity',
    discount: 'discount',
    purchasePrice: 'purchase price',
    sellingPrice: 'selling price',
    rentPrice: 'rent price',
    totalCost: 'total cost',
    user: 'user',
    created: 'created',
  },
  permissions: {
    title: 'permissions',
    premissionName: 'permission name',
    premissonCode: 'code',
    premissonDescription: 'description',
    addPermission: 'add permission',
  },
  actionButtons: {
    view: 'view',
    delete: 'delete',
    archive: 'archive',
    discount: 'discount',
    addAmount: 'amount',
    edit: 'edit',
    confirm: 'confirm',
    allTasks: 'all tasks',
    add: 'add',
    selectUser: 'select user',
    endDate: 'end date',
  },
};

export default enJSON;
